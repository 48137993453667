
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

$(function() {
  $('input.datepicker').bootstrapMaterialDatePicker({time: false});

  $('[data-confirm]').on('click', function() {
    if (confirm($(this).data('confirm'))) {
      return true;
    } else {
      return false;
    }
  });

  if ($('#materialBrowser').length > 0) {
    var url = $('#materialBrowser').data('url');
    $.get(url, function(resp) {
      $('#materialBrowser').replaceWith(resp);
    });
  }

  $(document).on('click', '#materialBrowser a', function() {
    var url = $(this).attr('href');
    $.get(url, function(resp) {
      $('#materialBrowser').replaceWith(resp);
    });
    return false;
  });

  $('#mobile-bars').on('click', function() {
    $('#mobile-menu > ul').slideToggle(400);
    return false;
  });

  var clipboard = new Clipboard('.btn-clipboard');

  var pageH = $(window).outerHeight();
  if ($('.page-header').length > 0) {
    pageH -= $('.page-header').outerHeight();
  }
  if ($('.page-heading').length > 0) {
    pageH -= $('.page-heading').outerHeight();
  }
  pageH -= $('#page-footer').outerHeight();
  $('#content').css('minHeight', pageH + 'px'); 
});